body {
  font-size: rem_calc(16);
}

p {
  font-size: inherit;
  line-height: 1.5;

  .u-text--enlarge {
    font-size: rem_calc(22);
  }

  .u-text--ensmall {
    font-size: rem_calc(15);
  }

  @include bp-custom(1680, 'max') {
    .u-text--enlarge {
      font-size: rem_calc(18);
    }
  }

  @include bp-xxlarge('max') {
    .u-text--enlarge {
      font-size: rem_calc(17);
    }
  }

  @include bp-xlarge('max') {
    .u-text--enlarge {
      font-size: rem_calc(16);
    }
  }

  @include bp-medium('max') {
    font-size: rem_calc(15);

    .u-text--ensmall {
      font-size: rem_calc(14);
    }
  }
}

.u-text--light {
  font-weight: 300;
}
