@mixin btn-style($bg-color, $bg-color-hover, $color, $color-hover, $border, $border-hover: $border) {
  background-color: $bg-color;
  color: $color;
  border: $border;

  &:hover,
  &:active,
  &:focus {
    background-color: $bg-color-hover;
    color: $color-hover;
    border: $border-hover;
  }
}
