.c-header {
  &__inner {
    width: 100%;
    height: rem_calc(110);

    @include bp-large('max') {
      height: rem_calc(80);
    }
  }

  &--shrink &__inner {
    height: rem_calc(90);

    @include bp-large('max') {
      height: rem_calc(60);
    }
  }

  &--container &__inner {
    max-width: $global-max-width;
  }

  &--floating &__inner {
    height: rem_calc(90);

    @include bp-large('max') {
      height: rem_calc(60);
    }
  }

  &--floating:not(&--full) &__container {
    max-width: $global-max-width - rem_calc(26);

    .body__fixed-header--floating & {
      max-width: none;
    }
  }

  &--floating,
  &--fixed {
    @include bp-large {
      border: rem_calc(20) solid transparent;
    }

    @include bp-xxlarge {
      border: rem_calc(30) solid transparent;
    }
  }

  &__donate {
    float: right;
    padding: 0;

    .c-header--static.c-header--image & {
      padding-top: rem_calc(30);
    }

    .c-btn {
      margin: 0;
    }

    .c-mobile-nav & {
      float: none;
      padding: rem_calc(10);
      text-align: center;
    }
  }

  &--floating#{&}--shrink &__container {
    max-width: none;
  }

  &--static#{&}--image &__container {
    background-position: top center;
  }

  &--static#{&}--image {
    .c-header__inner {
      height: rem_calc(300);

      @include bp-xxlarge('max') {
        height: rem_calc(275);
      }

      @include bp-large('max') {
        height: rem_calc(200);
      }
    }
  }

  &__inner {
    flex-flow: row wrap;

    .c-header--static.c-header--image & {
      align-items: flex-start;
    }
  }
}

.c-header__logo {
  width: 20%;
  height: auto;

  body:not(.home) & {
    padding-top: rem_calc(30);
  }

  a {
    height: 100%;
    width: 100%;
    max-width: rem_calc(300);
  }

  @include bp-xlarge-medium('max') {
    width: 17%;
  }

  @include bp-large('max') {
    max-width: rem_calc(190);
    width: 100%;
    height: 150%;

    a {
      height: 100%;
      display: block;
    }

    img {
      display: block;
      max-height: 100%;
    }
  }
}

.c-header__nav {
  width: 80%;

  @include bp-xlarge-medium('max') {
    width: 83%;
  }

  @include bp-large('max') {
    width: auto;
  }
}

.c-header__bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  width: 100%;

  .c-header__page-title {
    color: $color-white;
  }
}

.c-search {
  background-color: rgba($color-grey, 0.95);

  &__form {
    max-width: 60%;
    width: 95%;
    border: rem-calc(20) solid $color-theme;

    @include bp-large('max') {
      border-width: rem-calc(10);
      max-width: rem_calc(500);
    }

    &:hover {
      border-color: $color-theme-light !important;
    }
  }

  [type=text]#{&}__input {
    height: rem-calc(50);

    @include bp-large('max') {
      height: rem-calc(40);
      padding: rem-calc(10);
    }
  }

  &__btn {
    height: rem-calc(50);

    @include bp-large('max') {
      height: rem-calc(40);
    }
  }

  &__close {
    color: $color-underline-theme;

    &:hover {
      color: $color-theme-light !important;
      text-decoration: none !important;
    }
  }
}
