.c-sidebar {
  &__custom-content {
    max-width: rem_calc($sidebar_max_width);

    .c-sidebar--4 & {
      max-width: rem_calc($sidebar_max_width_lg);
    }
  }
}

.c-sidebar__widget {
  max-width: rem_calc($sidebar_max_width);

  .c-sidebar--4 & {
    max-width: rem_calc($sidebar_max_width_lg);
  }

  .c-footer__sidebar & {
    max-width: none;
  }
}

.c-sidebar__header {
  border-bottom: rem_calc(4) solid $color-theme;
  color: $color-theme;

  .c-footer__sidebar & {
    border: none;
  }
}

.widget_nav_menu,
.widget_pages,
.c-side-nav,
.widget_recent_entries,
.widget_rss {
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      margin: 0;

      a {
        padding: rem_calc(5 25);
        font-size: rem_calc(20);
        border: none;
        color: $color-link;

        .c-side-nav__icon {
          fill: $color-link;
        }

        /* stylelint-disable selector-no-qualifying-type */

        &:hover,
        &.c-side-nav__link--current,
        &.c-side-nav__sublink--current {
          background-color: $color-border;
        }

        /* stylelint-enable selector-no-qualifying-type */
      }

      /* stylelint-disable selector-no-qualifying-type */

      &.current-menu-item > a,
      &.current_page_item > a,
      .c-side-nav__link--current > a,
      .c-side-nav__link--current:hover > a {
        background-color: $color-border;
      }

      /* stylelint-enable selector-no-qualifying-type */
    }
  }
}

.widget__content-box-inner {
  max-width: rem_calc($sidebar_max_width);

  .c-sidebar--4 & {
    max-width: rem_calc($sidebar_max_width_lg);
  }

  @include bp-large('max') {
    max-width: rem_calc(420);
  }

  @include bp-medium('max') {
    max-width: rem_calc(380);
  }
}

.widget_x5_widget_upcoming_events {
  p {
    border: rem_calc(1) solid $color-border;
  }

  a {
    color: $color-black;
    transition: $global-transition;
  }

  &:hover a {
    background-color: $color-border;
  }
}

.widget__newsletter {
  background-color: $color-grey-lighter;

  &-link {
    color: $color-grey-dark;

    &:hover .widget__newsletter-label {
      color: $color-grey-dark;
    }
  }
}


.c-footer__sidebar {
  .widget__html-content {
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        :before {
          content: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='10' width='10'><circle cx='5' cy='5' r='4' stroke='#57accb' stroke-width='1' fill='#ffffff' fill-opacity='0' /></svg>"); /* stylelint-disable-line string-quotes */
        }

        a {
          color: $color-theme-dark;
        }
      }
    }
  }

  body.home & { /* stylelint-disable-line selector-no-qualifying-type */

    .home-hide { /* stylelint-disable-line selector-no-qualifying-type */
      display: none;
    }
  }
}

.c-footer__sidebar {
  .c-sidebar__widget {
    max-width: none;
    margin: auto;

    @include bp-large('max') {
      max-width: rem_calc($sidebar_max_width);

      .c-sidebar--4 & {
        max-width: rem_calc($sidebar_max_width_lg);
      }
    }
  }
}
