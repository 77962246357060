.c-listing__item {
  border-bottom: none;

  &:after {
    position: relative;
    display: block;
    content: '';
    width: 25%;
    padding-top: 20px;
    border-bottom: rem_calc(3) solid $color-black;

    @include bp-large('max') {
      margin: auto;
    }
  }

  @include bp-large('max') {
    a {
      float: none !important;
    }
  }
}

.c-listing__title {
  margin-bottom: rem_calc(20);

  @include bp-large('max') {
    margin-bottom: rem_calc(15);
  }

  &--right {
    margin-top: 0;
  }

  & + p {
    margin-bottom: 0;
  }
}

.c-listing__link {
  color: $color-theme;
  text-decoration: none;
  transition: $global-transition;
  font-size: rem_calc(20);

  &:hover {
    color: $color-link;
  }
}

.c-page-content .o-layout__item {
  > hr {
    display: none;
  }
}
