.c-heading {
  border-bottom: none;
  color: $color-black;
  font-weight: 700;
}

.c-heading--secondary {
  color: $color-grey-dark;
  border: none;
  margin-top: rem_calc(20);
}

.c-heading--big {
  font-size: rem-calc(20);

  @include bp-medium {
    font-size: rem-calc(26);
  }
}

.c-page__title {
  font-size: rem-calc(24);
  margin: rem_calc(20 0);
  color: $color-black;
  border: none;
  font-weight: 700;

  // @include bp-medium {
  //   font-size: rem-calc(24);
  // }

  // @include bp-large {
  //   font-size: rem-calc(28);
  // }
}
