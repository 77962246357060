.c-member__inner {
  text-align: left;
  border-bottom: rem_calc(4) solid $color-text2;
  margin-bottom: rem_calc(25);
  max-width: rem_calc(210);
}

.single-x5_press_people .c-page-content,
.c-member__inner {
  a {
    color: $color-link-alt;

    &:hover {
      color: $color-link-alt-hover;
    }
  }
}

.c-member__image {
  img {
    max-width: rem_calc(150);
    height: auto;
  }
}

.c-member__title {
  background-color: transparent;
  font-size: rem-calc(16);
  margin-bottom: 0;
}

.c-member__link {
  color: $color-link-alt;
  padding: rem-calc(10 0 0);

  &:hover {
    color: $color-link-alt-hover;
  }
}

.c-member__content {
  padding-left: 0;
  padding-right: 0;
}
