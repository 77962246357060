.c-breadcrumb__item:not(:last-child) {
  &::after {
    content: '';
    background-color: $color-grey;
  }
}

.c-breadcrumb__link,
.c-breadcrumb__item a {
  color: $color-grey-dark;

  &:hover {
    color: $color-grey-dark;
  }
}
