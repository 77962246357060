.c-courses__meta {
  border-bottom: rem-calc(1) solid $color-grey-medium;
  color: $color-grey-medium;
}

.c-courses__teaser {
  color: $color-grey-dark;
}

.c-courses__filter {
  border: rem-calc(2) solid $color-grey-light;
  border-left-width: 0;
  border-right-width: 0;
}

.c-courses__filter-reset {
  color: $color-grey-dark;

  &:hover {
    color: $color-grey-dark;
  }
}

.c-courses__item {
  border-bottom: rem-calc(2) solid $color-grey-light;
}
