/* stylelint-disable selector-max-id */

#baguetteBox-overlay {
  background-color: rgba(150, 238, 234, 0.9) !important;

  .full-image {
    figcaption {
      color: #eee;
    }
  }

  .baguetteBox-button {
    background-color: rgba(255, 255, 255, 0) !important;

    svg {
      polyline,
      g {
        stroke: $color-theme !important;
      }
    }
  }
}

/* stylelint-enable */
