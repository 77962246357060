///* ========================================================================
//   #BREAKPOINTS
//   ======================================================================== */

@mixin bp-small($type: 'min') {
  @media (#{$type}-width: $bp-small) {
    @content;
  }
}

@mixin bp-small-medium($type: 'min') {
  @media (#{$type}-width: $bp-small-medium) {
    @content;
  }
}

@mixin bp-medium($type: 'min') {
  @media (#{$type}-width: $bp-medium) {
    @content;
  }
}

@mixin bp-large($type: 'min') {
  @media (#{$type}-width: $bp-large) {
    @content;
  }
}

@mixin bp-xlarge($type: 'min') {
  @media (#{$type}-width: $bp-xlarge) {
    @content;
  }
}

@mixin bp-xlarge-medium($type: 'min') {
  @media (#{$type}-width: $bp-xlarge-medium) {
    @content;
  }
}

@mixin bp-xxlarge($type: 'min') {
  @media (#{$type}-width: $bp-xxlarge) {
    @content;
  }
}

@mixin bp-custom($value: 1500, $type: 'min') {
  @media (#{$type}-width: rem-calc($value)) {
    @content;
  }
}

@mixin bp-full($type: 'min') {
  @media (#{$type}-width: $bp-full) {
    @content;
  }
}
