.c-hero {
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(61, 5, 27, 0.4);
  }
}

.c-hero--imageless {
  background-color: $color-grey-light;
}

.c-hero__content {
  z-index: 2;
}

.c-hero__img {
  opacity: 1;
}

.c-hero__title {
  color: $color-white;
}

.c-hero__meta {
  font-size: rem-calc(14);
  color: $color-white;

  @include bp-medium {
    font-size: rem-calc(20);
  }

  @include bp-large {
    font-size: rem-calc(24);
  }
}
