.c-pagination {
  @include bp-large('max') {
    text-align: center;
  }
}

.c-pagination__items {
  @include bp-large('max') {
    width: 100%;
  }
}

.c-pagination__link {
  margin: rem_calc(2);
  padding-left: rem-calc(5);
  padding-right: rem-calc(5);
  color: $color-theme;
  border: none;
  font-size: rem_calc(16);
  display: none;

  .c-pagination__item & {
    display: inline-block;
  }

  .icon {
    fill: $color-theme;
    top: rem-calc(2);
    width: 1rem;
    height: 1rem;
  }

  &--current,
  &:hover {
    border-color: $color-theme;
    text-decoration: none;
  }
}
