.c-book {
  a {
    &:hover {
      color: $color-link;
    }
  }

  &__inner {
    height: 100%;
    border: 1px solid $color-grey-lighter;
  }

  @include bp-small('max') {
    &__inner {
      max-width: rem_calc(350);
    }
  }
}

.c-book__cover {
  width: 50%;
  max-width: rem_calc(190);

  @include bp-small('max') {
    width: 100%;
  }

  .c-book__single & {
    width: 100%;
    max-width: rem_calc(350);
  }
}

.c-book__title {
  color: $color-grey-dark;
}

.c-book__authors {
  h6 {
    color: $color-grey-light;
  }

  a {
    color: $color-grey;
  }
}

.c-book__publisher,
.c-book__isbn {
  font-size: rem_calc(16);
}

.c-books__orderby {
  label {
    color: $color-grey;
  }
}
