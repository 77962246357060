.c-footer {
  background-color: $color-grey-super-light;

  &__column {
    @include bp-large('max') {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }

    &-header {
      h3 {
        margin: 0;
        border: none;
        color: $color-text2;
        font-weight: 700;
      }
    }
  }

  .c-social-media__link {
    border-color: $color-black;

    .c-social-media__icon {
      fill: $color-black;
    }
  }

  .js-google-map {
    max-width: rem_calc(330);
  }

  .c-menu__items {
    li {
      margin-bottom: 0;
    }

    a {
      color: $color-black;
      line-height: 1.5rem;
      text-transform: uppercase;
    }
  }

  .c-footer__inner {
    max-width: 366px;

    @include bp-large {
      max-width: 100%;
    }
  }
}

.c-footer__copyright {
  background-color: $color-grey-extra-light;
  font-size: rem_calc(17);
  border-top: 1px solid $color-grey-lighter;

  .c-footer__logo {
    max-width: rem_calc(160);
  }
}
