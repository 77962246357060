.c-btn {
  font-size: rem_calc(18);
  font-weight: 400;
  padding: rem-calc(10 20);
  border: 1px solid $color-link-alt;
  white-space: nowrap;
  line-height: 1.05;

  .c-icon {
    width: rem-calc(26);
    height: rem-calc(18);
  }

  @include bp-medium('max') {
    font-size: rem_calc(16);
  }

  .c-header__donate & {
    font-size: rem_calc(20);

    @include bp-xlarge('max') {
      font-size: rem_calc(18);
    }

    @include bp-large('max') {
      font-size: rem_calc(16);
    }
  }

  &.donate-btn {
    padding: rem_calc(20 70);
    font-size: rem_calc(20);

    @include bp-large('max') {
      font-size: rem_calc(18);
      padding: rem_calc(16 50);
    }

    @include bp-medium('max') {
      font-size: rem_calc(16);
      padding: rem_calc(13 40);
    }
  }

  &--small {
    padding: rem-calc(9 15);

    .c-icon {
      width: rem-calc(22);
      height: rem-calc(14);
    }
  }

  &--medium {
    padding: rem-calc(13 25);

    @include bp-xlarge('max') {
      padding: rem-calc(11 20);
    }

    @include bp-medium('max') {
      padding: rem-calc(10 15);
    }

    .c-icon {
      width: rem-calc(30);
      height: rem-calc(22);
    }
  }

  &--large {
    padding: rem-calc(15 30);

    @include bp-xlarge('max') {
      padding: rem-calc(13 25);
    }

    @include bp-medium('max') {
      padding: rem-calc(11 20);
    }

    .c-icon {
      width: rem-calc(34);
      height: rem-calc(25);
    }
  }
}

// parameters - $bg-color, $bg-color-hover, $color, $color-hover, $border, $border-hover: $border

.c-btn--primary {
  @include btn-style(transparent, $color-theme, $color-theme, $color-white, rem-calc(2) solid $color-theme);
}

.c-btn--primary-filled {
  @include btn-style($color-theme, transparent, $color-white, $color-theme, rem-calc(2) solid $color-theme);
}

.c-btn--secondary {
  @include btn-style(transparent, $color-theme-light, $color-theme-light, $color-white, rem-calc(2) solid $color-theme-light);
}

.c-btn--secondary-filled {
  @include btn-style($color-theme-light, transparent, $color-white, $color-theme-light, rem-calc(2) solid $color-theme-light);
}

.c-btn--link {
  @include btn-style(transparent, $color-link, $color-link, $color-white, rem-calc(2) solid $color-link);
}

.c-btn--link-filled {
  @include btn-style($color-link, transparent, $color-white, $color-link, rem-calc(2) solid $color-link);
}

.c-btn--link-underline {
  @include btn-style(transparent, transparent, $color-link, $color-link-hover, none);
  padding-left: 0;
  padding-right: 0;
  border: none;
  border-bottom: none;

  &:hover,
  &:active,
  &:focus {
    text-decoration: underline !important;
    border-bottom: none;
  }
}

.c-btn--primary-light {
  @include btn-style($color-theme, $color-theme-light, $color-white, $color-white, rem-calc(2) solid $color-theme, rem-calc(2) solid $color-theme-light);
}

.c-btn--secondary-light {
  @include btn-style($color-white, $color-theme-alt, $color-theme-dark, $color-theme-dark, rem-calc(2) solid $color-white, rem-calc(2) solid $color-theme-alt);
}

.c-btn--arrow-primary,
.c-btn--arrow-secondary {
  padding: rem-calc(3 10);
  border: none;
}

.c-btn--arrow-primary {
  color: $color-grey-dark;

  .c-icon {
    fill: $color-theme-alt;
  }

  &:hover,
  &:active,
  &:focus {
    color: $color-theme-alt;
  }
}

.c-btn--arrow-secondary {
  color: $color-grey-dark;

  .c-icon {
    fill: $color-theme;
  }

  &:hover,
  &:active,
  &:focus {
    color: $color-theme;
  }
}
