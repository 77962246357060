.c-calendar__pager {
  background-color: $color-grey-light-medium;
  border-radius: $global-radius;
  height: rem-calc(30);
}

.c-calendar__pager-prev,
.c-calendar__pager-next {
  background-color: $color-white;
  border-radius: 0 $global-radius $global-radius 0;
  border: 1px solid $color-grey-lighter-medium;
  height: rem-calc(36);

  &:hover {
    border-color: $color-grey-medium-light;
  }
}

.c-calendar__pager-prev {
  border-radius: $global-radius 0 0 $global-radius;
}

.c-calendar__pager-link {
  font-size: rem-calc(14);
  line-height: rem-calc(36);
  color: $color-grey-medium;

  &:hover {
    color: $color-grey-medium;
  }
}

.c-calendar__pager-link--inactive {
  color: $color-grey-light-medium;

  &:hover {
    color: $color-grey-light-medium;
  }
}

.c-calendar__header {
  background-color: $color-grey-lighter;
  color: $color-grey-medium-light;
  border: 1px solid $color-grey-lighter-medium;
}

.c-calendar__date-box,
.c-calendar__single-day {
  width: 14%;
  border: 1px solid $color-grey-lighter-medium;
  color: $color-grey-medium-light;
}

.c-calendar__date-box--past,
.c-calendar__date-box--future,
.c-calendar__single-day--past,
.c-calendar__single-day--future {
  background-color: lighten($color-grey-lighter, 2.5%);
  color: $color-grey-light;
}

.c-calendar__date-box--today {
  border: rem-calc(2) solid $color-theme;
  border-bottom-width: 0;
}

.c-calendar__single-day--today {
  border: rem-calc(2) solid $color-theme;
  border-top-width: 0;
}

.c-calendar__single-day .c-calendar__inner {
  height: rem-calc(150);
  line-height: rem-calc(44);

  @include bp-xlarge-medium('max') {
    height: rem_calc(120);
  }
}

.c-calendar__single-day .c-calendar__inner--full {
  height: rem_calc(205);
  font-size: rem-calc(13);

  @include bp-xlarge-medium('max') {
    height: rem_calc(160);
  }
}

.c-calendar__event-title {
  a {
    &:hover {
      color: $color-theme;
    }
  }
}

.c-calendar__list-item {
  border-bottom: 1px solid $color-grey-lighter-medium;
}

.c-calendar__list-item--today {
  background-color: $color-grey-light;
}
