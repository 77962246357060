.c-page__hero {
  height: rem_calc(400);

  @include bp-large('max') {
    height: rem_calc(300);
  }

  @include bp-medium('max') {
    height: rem_calc(250);
  }

  &--container {
    max-width: $global-max-width;
  }
}
