.c-main-nav {
  text-align: center;
}

.c-main-nav__items {
  float: none;
  width: auto;
  padding: 0 0 rem_calc(20) 0;
  display: inline-block;

  .c-header--static.c-header--image & {
    padding: rem_calc(30) 0 0 0;
  }
}

.c-main-nav__item {
  &.menu-item-has-children {
    &:after,
    &:before {
      border-left: rem-calc(5) solid transparent;
      border-right: rem-calc(5) solid transparent;
      border-top: rem-calc(7) solid;
    }

    &:before {
      bottom: - rem_calc(9);
      border-top-color: transparent;
    }
  }
}

.c-main-nav__link {
  padding-top: rem-calc(12);
  padding-bottom: rem-calc(12);
  font-size: rem_calc(18);
  text-transform: uppercase;
  text-decoration: none !important;

  @include bp-custom(1470, 'max') {
    font-size: rem_calc(16);
  }

  .c-main-nav__item--transparent & {
    .c-header--static.c-header--image &,
    .c-header--static.c-header--none &,
    .c-header--none & {
      padding-top: rem-calc(31);
      padding-bottom: rem-calc(31);
    }
  }
}

.c-main-nav__sublink {
  text-transform: uppercase;
  text-decoration: none !important;
}

.c-main-nav__search-trigger {
  margin-left: rem-calc(15);

  @include bp-xlarge('max') {
    margin-left: rem-calc(5);
  }
}

.c-secondary-nav {
  margin: rem_calc(10) 0 0;
  display: block;

  @include bp-large('max') {
    display: none;
  }

  li {
    padding: rem_calc(0 25 0 0);
    margin-bottom: 0;
  }

  a {
    display: block;
    padding: rem_calc(0 0 20);
    font-size: rem_calc(16);
    color: $color-white;
    border-bottom: rem_calc(3) solid rgba(255, 255, 255, 0);
    transition: all 0.3s ease-in-out;

    &:hover,
    &.current { /* stylelint-disable-line selector-no-qualifying-type */
      text-decoration: none;
      border-bottom: rem_calc(3) solid $color-white;
    }

    &.current { /* stylelint-disable-line selector-no-qualifying-type */
      font-weight: 600;
    }
  }
}
