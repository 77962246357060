.c-social-media__link {
  border: rem-calc(2) solid $color-black;

  &:hover {
    border-color: $color-underline-theme;

    .c-social-media__icon {
      fill: $color-underline-theme;
    }
  }
}
