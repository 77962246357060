.select2-container .select2-selection--single {
  height: rem_calc(38);
  border: rem-calc(3) solid $color-black;

  @include bp-xxlarge('max') {
    height: rem_calc(38);
  }

  @include bp-large('max') {
    height: rem_calc(38);
  }

  @include bp-medium('max') {
    height: rem_calc(36);
    border-width: rem-calc(1);
  }

  &.select2--small {
    height: rem_calc(36);
  }

  &.select2--xsmall {
    height: rem_calc(34);
    border: rem-calc(3) solid $color-theme;
  }
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: rem_calc(35);
  color: $color-grey-dark;

  @include bp-xxlarge('max') {
    line-height: rem_calc(35);
  }

  @include bp-large('max') {
    line-height: rem_calc(35);
  }

  @include bp-medium('max') {
    line-height: rem_calc(34);
  }
}

.select2-container--default .select2-selection--single.select2--small .select2-selection__rendered {
  line-height: rem_calc(30);

  @include bp-medium('max') {
    line-height: rem_calc(28);
  }
}

.select2-container--default .select2-selection--single.select2--xsmall .select2-selection__rendered {
  line-height: rem_calc(34);

  @include bp-medium('max') {
    line-height: rem_calc(30);
  }
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: rem_calc(35);

  @include bp-xxlarge('max') {
    height: rem_calc(35);
  }

  @include bp-large('max') {
    height: rem_calc(35);
  }

  @include bp-medium('max') {
    height: rem_calc(34);
  }
}

.select2-container--default .select2-selection--single.select2--small .select2-selection__arrow {
  height: rem_calc(36);
}

.select2-container--default .select2-selection--single.select2--xsmall .select2-selection__arrow {
  height: rem_calc(34);
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: $color-grey-dark transparent transparent transparent;
  border-width: rem_calc(7) rem_calc(6) 0 rem_calc(6);
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent $color-grey-dark transparent;
  border-width: 0 rem_calc(6) rem_calc(7) rem_calc(6);
}

.select2-dropdown {
  background-color: $color-white;
  border: rem_calc(3) solid $color-black;

  &.select2--xsmall {
    border-width: rem_calc(2);
  }
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: rem_calc(15);
  padding-right: rem_calc(15);
  font-size: rem_calc(16);

  @include bp-medium('max') {
    padding: rem-calc(5 10);
    font-size: rem_calc(16);
  }
}

.select2-results__option {
  padding: rem_calc(5 15);
  margin: 0;

  @include bp-medium('max') {
    padding: rem_calc(4 10);
    font-size: rem_calc(15);
  }

  .select2-results__options.first-as-label &:first-child {
    padding: rem_calc(10 15);
    background-color: $color-theme-light;
    color: $color-white;

    @include bp-medium('max') {
      padding: rem_calc(8 15);
    }
  }
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: $color-grey-lighter;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: $color-grey-medium-light;
  color: $color-white;
}
