.c-mobile-nav__trigger,
.c-main-nav__trigger,
.c-mobile-nav__close {
  border-top-color: $color-white;

  &::after,
  &::before {
    content: '';
    border-top-color: $color-white;
  }
}
